import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VideoModal from '../components/VideoModal';
import styles from '../styles/KeyActivitiesPage.module.css';
import { Button, Alert, CircularProgress } from '@mui/material';
import Storage from '../utilities/Storage';
import Logout from '../components/Logout';
import { config } from "../config/config";

const ValuePropositions = () => {
    const token = Storage.getItem('accesstoken');
    const [valuePropositions, setValuePropositions] = useState('');
    const [caseStudies, setCaseStudies] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false); // State for managing loader
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchValuePropositions = async () => {
            setLoading(true);
            try {
                const response = await fetch(config.apiUrl + '/user/get_value_propositions', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'locale': 'en',
                        'tokentype': 'email',
                        'accesstoken': token,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setValuePropositions(data.valuePropositions);
                } else {
                    throw new Error('Failed to load Value Propositions');
                }
            } catch (error) {
                console.error('Error fetching value propositions:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchValuePropositions();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(config.apiUrl + '/user/save_value_propositions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                },
                body: JSON.stringify({ value_propositions: valuePropositions }),
            });

            if (response.status === 200) {
                navigate('/channels');
            } else {
                const data = await response.json();
                throw new Error(data.message || 'Unknown error saving Value Propositions');
            }
        } catch (error) {
            console.error('Error saving value propositions:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleGenerateCaseStudies = async () => {
        if (!valuePropositions.trim()) {
            setError('Please enter Value Propositions before generating case studies.');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(config.apiUrl + '/user/generate_case_studies', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                },
                body: JSON.stringify({ type: 'value_propositions', content: valuePropositions }),
            });

            if (response.status === 200) {
                const data = await response.json();
                setCaseStudies(data.caseStudies);
                setSuccess('Case studies generated successfully');
            } else {
                throw new Error('Failed to generate case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                }}>
                    <CircularProgress />
                </div>
            )}

            <div className="keyActivitiesPage">
            <Logout/>
                <div className="container">
                    <div className="card_black">
                        <div className="row">
                            <div className="col-md-4 left-section">
                                <h2 className="text-left">Value Propositions</h2>
                                <p className="text-white description">Value Propositions describe the benefits your customers will receive from your products or services. Please review and edit the pre-filled suggestions below to best match your business idea.</p>
                                <div className="buttons-wrapper">
                                    <button type="button" className="btn btn-info btn-custom" data-toggle="modal" onClick={() => setShowModal(true)}>Watch Video</button>
                                    <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/value-propositions-help')}>Value Propositions Help</button>
                                </div>
                            </div>
                            <div className="col-md-8 right-section">
                                {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
                                {success && <Alert severity="success" onClose={() => setSuccess('')}>{success}</Alert>}

                                <form id="ValuePropoForm" className="form-main">
                                    <div className="form-group d-flex flex-column">
                                        <textarea
                                            value={valuePropositions}
                                            onChange={(e) => setValuePropositions(e.target.value)}
                                            className="form-control" id="ValuePropo" name="ValuePropo" rows="15" required>
                                        </textarea>
                                        <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" className="btn btn-info btn-primary-custom">
                                            Save
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/h1f94VOhWm4?autoplay=1" onClose={() => setShowModal(false)} title="Value Propositions Video" />}
            </div>
        </>
    );
};

export default ValuePropositions;

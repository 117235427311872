import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from "../config/config";

import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

const GoogleLogin = () => {
  const [googleUrl, setGoogleUrl] = useState('');
  const navigate = useNavigate();

  // Fetch Google login URL from Flask API using fetch
  useEffect(() => {
    fetch(config.apiUrl+'/google/login',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'locale': 'en',
        },
    } 
    )  // Adjust your API URL here
      .then((response) => response.json())
      .then((data) => {
        setGoogleUrl(data.res_data.data.google_url);
      })
      .catch((error) => {
        console.error('Error fetching Google login URL:', error);
      });
  }, []);

  // Handle Google login redirect
  const handleGoogleLogin = () => {
    if (googleUrl) {
      window.location.href = googleUrl; // Redirect to Google login page
    }
  };

  return (
    <div>
      {/* <button onClick={handleGoogleLogin}>
        Login via Google
      </button> */}
       <Button
       onClick={handleGoogleLogin}
    variant="contained"
    style={{
      backgroundColor: '#db4437',
      color: '#fff',
      padding: '10px 20px',
      textTransform: 'none',
      fontSize: '16px',
      display: 'flex',
      justifyContent: 'center',
    }}
    startIcon={<GoogleIcon />}
    fullWidth
  >
    Login with Google
  </Button>
    </div>
  );
};

export default GoogleLogin;

import React, { useState, useEffect ,useContext} from "react";
import { useNavigate } from "react-router-dom";
import { Button, Alert, CircularProgress } from "@mui/material";
import { config } from "../config/config";
import Logout from '../components/Logout';
import Storage from '../utilities/Storage';



const BusinessIdea = () => {
  //  const token = Storage.getItem('accesstoken');
  const token = Storage.getItem('accesstoken');
  const [ideaName, setIdeaName] = useState("");
  const [businessIdea, setBusinessIdea] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");



    try {
    //  alert(token) 
      // Check if a plan exists
      console.log('************' + token )
      //alert(token);
      const planExistsResponse = await fetch(config.apiUrl+"/user/check_plan", {
        method: "GET",
        // credentials: "include",
        headers: {
          'Content-Type': 'application/json',
          'locale': 'en',
          'tokentype': 'email',
          'accesstoken': token,
         
        },
      });

      if (planExistsResponse.status === 404) {
        // If no plan exists, create a new plan
        const createPlanResponse = await fetch(config.apiUrl+"/user/create_plan", {
          // credentials: "include",
          // mode: 'no-cors',
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'locale': 'en',
            'tokentype': 'email',
            'accesstoken': token,
           
          },
        
          body: JSON.stringify({ planName: ideaName }),
        });

        if (createPlanResponse.status !== 201) {
          throw new Error("Failed to create a new business plan.");
        }
      }

      // Save business idea
      const response = await fetch(config.apiUrl+"/user/save_business_idea", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'locale': 'en',
          'tokentype': 'email',
          'accesstoken': token,
         
        },
        // credentials: "include",
        body: JSON.stringify({ ideaName, businessIdea }),
      });

      const data = await response.json();

      if (response.status === 200) {
        localStorage.setItem("customerSegments", data.customerSegments);
        setSuccess("Business idea saved successfully!");
        setTimeout(() => {
          // window.location.href = "customerSegments.html";
          navigate('/customer-segments');
           
        }, 1000);
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };



  // useEffect(() => {
  //   if (Storage.getItem('accesstoken') == null) {
  //     navigate('/login');
  //   }
  // }, []);




  return (
    <>
    
      <div className="keyActivitiesPage">
      <Logout/>
        <section className="login-main">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="left-side position-relative mw_640">
                  <div className="video">
                    <iframe
                      className="video-stream"
                      src="https://www.youtube.com/embed/qGixSRWhSSI?autoplay=1"
                      title="Define Business Idea"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <h1 className="mt-5">
                    Welcome aboard! You haven't created any canvas yet.{" "}
                  </h1>

                  <p>
                    Let's bring your vision to life – create your business model
                    canvas now and turn your dreams into reality.
                  </p>
                </div>
              </div>
              <div className="col-md-6 right-side">
                <h2   className="">What’s Your Business Idea?</h2>
                {error && <Alert severity="error">{error}</Alert>}
                {success && <Alert severity="success">{success}</Alert>}
                <form
                  id="businessIdeaForm"
                  className="form-main"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <label for="ideaName">Idea Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ideaName"
                      name="ideaName"
                      required
                      value={ideaName}
                      onChange={(e) => setIdeaName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="businessIdea">
                      Problem Statement/Business Idea:
                    </label>
                    <textarea
                      className="form-control"
                      id="businessIdea"
                      name="businessIdea"
                      value={businessIdea}
                      onChange={(e) => setBusinessIdea(e.target.value)}
                      placeholder="Start by entering the demographics in the first line. Then, describe the problem you aim to solve, followed by a detailed explanation of your idea and how it addresses the problem."
                      rows="5"
                      maxlength="1000"
                      required
                    ></textarea>
                  </div>
                  {/* <button type="submit"  className="btn btn-custom ml-auto">Save</button> */}
                  <Button
                   className="btn btn-info btn-primary-custom"
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    style={{ marginTop: "16px" }}
                  >
                    {loading ? <CircularProgress size={24} /> : "Save"}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BusinessIdea;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from "../config/config";
import Storage from '../utilities/Storage';
import { CircularProgress, Alert } from '@mui/material';

const GoogleCallback = () => {
  const [loading, setLoading] = useState(true); // State for managing loader  
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const processGoogleLogin = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        if (!code) throw new Error('Missing authorization code');

        console.log('Authorization code:', code);

        // Payload for API request
        const payload = {
          req_param: {
            google_code: code,
          },
        };

        // Send authorization code to the Flask API
        const response = await fetch(`${config.apiUrl}/google/add-user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'locale': 'en',
          },
          body: JSON.stringify(payload),
        });
        
        const data = await response.json();
        
        if (response.ok && data.res_data?.data?.accesstoken) {
          const accessToken = data.res_data.data.accesstoken;
          Storage.setItem('accesstoken', accessToken);
          console.log('Login successful:', data);

          // Fetch extra user data
          const extraDataResponse = await fetch(`${config.apiUrl}/user/get_extradata`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'locale': 'en',
              'tokentype': 'email',
              'accesstoken': accessToken,
            },
          });

          const extraData = await extraDataResponse.json();
          if (extraDataResponse.ok && extraData.redirectTo) {
            navigate(extraData.redirectTo === 'cost-structure' ? '/dashboard' : `/${extraData.redirectTo}`);
          } else {
            navigate('/dashboard');
          }
        } else {
          throw new Error(data.error?.message || 'Login failed');
        }
      } catch (err) {
        console.error('Error during Google login:', err);
        setError(err.message);
        setTimeout(() => navigate('/login'), 5000); // Redirect to login after 5 seconds
      } finally {
         setLoading(false);
      }
    };

    processGoogleLogin();
  }, [location, navigate]);

  return (
    <div className="container mx-auto">
      {loading ? (
        <div style={loaderStyle}>
          <CircularProgress />
        </div>
      ) : (
        <div className="mx-auto pd-10">
          <h2 className="text-center mt-20 text-white">Processing google login...</h2>
          {error && <Alert severity="error">{error}</Alert>}
        </div>
      )}
    </div>
  );
};

// Styles for the loader overlay
const loaderStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
};

export default GoogleCallback;

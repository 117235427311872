import { FunctionComponent } from "react";

const Error: FunctionComponent = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="mx-auto pd-10 text-center">
          <h2 className="text-center mt-20 text-white">404 Error Page.</h2>
          <p className="text-white text-center mt-10">
            Sorry, This page doesn't exist
          </p>
          <a href="/">GO Back</a>
        </div>
      </div>
    </>
  );
};
export default Error;

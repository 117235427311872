import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../config/config";
import Storage from "../utilities/Storage";
import LinkedInLogin from "../components/LinkedInLogin";
import GoogleLogin from "../components/GoogleLogin";
import { Button, Alert, CircularProgress } from "@mui/material";

const Login = () => {
  // const { login,token } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    try {
      const payload = {
        req_param: {
          email_phone: email,
          password: password,
        },
      };
      const response = await fetch(config.apiUrl + "/user/login", {
        method: "POST",
        // mode: 'no-cors',
        headers: {
          "Content-Type": "application/json",
          locale: "en",
        },

        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.status === 200) {
        Storage.setItem("accesstoken", data.res_data.data.accesstoken);

        if (data.res_data.data.accesstoken) {
          const LastPageExistsResponse = await fetch(
            config.apiUrl + "/user/get_extradata",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                locale: "en",
                tokentype: "email",
                accesstoken: data.res_data.data.accesstoken,
              },
            }
          );
          const userdata = await LastPageExistsResponse.json();
          if (LastPageExistsResponse.status === 200) {
            console.log("userdata==", userdata);
            if (userdata.redirectTo) {
              if (userdata.redirectTo == "cost-structure") {
                navigate("/dashboard");
              } else {
                navigate("/" + userdata.redirectTo);
              }
            } else {
              navigate("/dashboard");
            }
          }
        }
      } else {
        setError(data.error.message);
        // throw new Error(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="keyActivitiesPage">
        <div className="container">
          <div className="card_black">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <h2 className="text-center mt-5">Login</h2>
                <p className="text-white text-center">
                  Quick login via Google or LinkedIn
                </p>
                <GoogleLogin />
                <br />
                <LinkedInLogin />
                <p className="text-white text-center mt-10">Or</p>
                {error && <Alert severity="error">{error}</Alert>}

                <form
                  id="loginForm"
                  className="mt-4 form-main"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group d-flex flex-column">
                    <label for="email">Email:</label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      required
                    />
                  </div>
                  <div className="form-group d-flex flex-column">
                    <label for="password">Password:</label>
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      required
                    />
                  </div>
                  {/* <button type="submit"  className="btn btn-primary mt-4 ml-auto">Login</button> */}

                  <Button
                    className="btn btn-custom mt-4 ml-auto"
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    style={{ marginTop: "16px" }}
                  >
                    {loading ? <CircularProgress size={24} /> : "Login"}
                  </Button>
                  {/* <div  className="row justify-content-center">
                <div  className="col-md-8 mx-auto">
                 <p  className="text-center text-white mt-3">
                        Don't have an account? <a href="/register">Register</a>
                    </p>
                </div>
                 <div  className="col-md-4">
                 <Button
                       className="btn btn-custom mt-4 ml-auto"
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          style={{ marginTop: '16px' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </Button>
                </div>
                 </div> */}
                   <p  className="text-center text-white mt-3">
                        Don't have an account? <a href="/register">Register</a>
                    </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

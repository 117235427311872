import React, { useState, useEffect,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import Button from '../components/Button';
import VideoModal from '../components/VideoModal';
import styles from '../styles/KeyActivitiesPage.module.css';
import { config } from "../config/config";
import { Button, Alert, CircularProgress } from '@mui/material';
import Storage from '../utilities/Storage';
import Logout from '../components/Logout';

const CostStructure= () => {
     const token = Storage.getItem('accesstoken');
    const [showModal, setShowModal] = useState(false);
    const [costStructure, setCostStructure] = useState('');
    const [caseStudies, setCaseStudies] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false); // Loader state
    const navigate = useNavigate(); // For navigation

    // Fetch cost structure on component mount
    useEffect(() => {
        const fetchCostStructure = async () => {
            setLoading(true);
            try {
                const response = await fetch(config.apiUrl+'/user/get_cost_structure', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'locale': 'en',
                        'tokentype': 'email',
                        'accesstoken': token,
                       
                      },
                });

                if (response.ok) {
                    const data = await response.json();
                    setCostStructure(data.costStructure);
                } else {
                    throw new Error('Failed to load cost structure');
                }
            } catch (error) {
                console.error('Error fetching cost structure:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCostStructure();
    }, []);

    // Handle form submission to save cost structure
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(config.apiUrl+'/user/save_cost_structure', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
                // credentials: 'include',
                body: JSON.stringify({ costStructure: costStructure }),
            });

            if (response.status === 200) {
                navigate('/dashboard'); // Navigate to the dashboard page
            } else {
                const data = await response.json();
                throw new Error(data.message || 'Unknown error saving cost structure');
            }
        } catch (error) {
            console.error('Error saving cost structure:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Handle case studies generation
    const handleGenerateCaseStudies = async () => {
        if (!costStructure.trim()) {
            setError('Please enter Cost Structure before generating case studies.');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(config.apiUrl+'/user/generate_case_studies', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
                body: JSON.stringify({ type: 'costStructure', content: costStructure }),
            });

            if (response.ok) {
                const data = await response.json();
                setCaseStudies(data.caseStudies);
                setSuccess('Case studies generated successfully');
            } else {
                throw new Error('Failed to generate case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (

<>
{loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                }}>
                    <CircularProgress />
                </div>
            )}
<div className="keyActivitiesPage">
<Logout/>
        <div className="container">
        <div className="card_black">
            <div className="row">
                <div className="col-md-4 left-section">
                <h2 className="text-left">Cost Structure</h2>
                    <p className="text-white description">Cost Structure describes all costs incurred to operate a business model. Please review and edit the pre-filled suggestions below to best match your business idea.</p>
                    <div className="buttons-wrapper">
                        <button type="button" className="btn btn-info btn-custom" data-toggle="modal"  onClick={() => setShowModal(true)}>Watch Video</button>
                        <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/cost-structure-help')}>Cost Structure Help</button>
                    </div>
                </div>
                <div className="col-md-8 right-section">
                {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
            {success && <Alert severity="success" onClose={() => setSuccess('')}>{success}</Alert>}

           
                    <form id="costStructureForm" className="form-main">
                        <div className="form-group d-flex flex-column">
                            <textarea 
                             value={costStructure}
                             onChange={(e) => setCostStructure(e.target.value)}
                            className="form-control" id="costStructure" name="costStructure" rows="15" required></textarea>
                             {/* <button type="submit" onClick={handleSave} className="btn btn-info btn-primary-custom">Save</button> */}
                           <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" className="btn btn-info btn-primary-custom">
                            Save
                        </Button>
                        </div>
                    </form>
                 
                </div>
            </div>
        </div>
    </div>

    {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/ltaMbQ5O7ys?autoplay=1" onClose={() => setShowModal(false)} title="Cost Structure Video" />}
    </div>
    </>
    );
};

export default CostStructure;

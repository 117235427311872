import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from "../config/config";
import Storage from '../utilities/Storage';
import { Button} from '@mui/material';

const Logout= () => {
    // const { login,token } = useContext(AuthContext);
    const navigate = useNavigate();
  
    const handleSubmit = async (event) => {
      Storage.removeItem('accesstoken');
      navigate("/login")
    };
    useEffect(() => {
      if (Storage.getItem('accesstoken') == null) {
        navigate('/login');
      }
    }, []);
    return (

<>
<div  className="logoutDiv">
            <Button 
            onClick={handleSubmit} 
            variant="contained" color="primary" type="submit" className="btn btn-info btn-primary-custom">
                            Logout
                        </Button>
                        </div>
    </>
    );
};

export default Logout;

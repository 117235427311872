import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from "../config/config";

import { Button } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';

const LinkedInLogin = () => {
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const navigate = useNavigate();

  // Fetch LinkedIn login URL from Flask API using fetch
  useEffect(() => {
    fetch(config.apiUrl+'/linkedin/login',
    {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'locale': 'en',
        },
    } )
      .then((response) => response.json())
      .then((data) => {
        setLinkedinUrl(data.res_data.data.linkedin_url);
      })
      .catch((error) => {
        console.error('Error fetching LinkedIn login URL:', error);
      });
  }, []);

  // Handle LinkedIn login redirect
  const handleLinkedInLogin = () => {
    if (linkedinUrl) {
      window.location.href = linkedinUrl; // Redirect to LinkedIn login page
    }
  };

  return (
    <div>
      {/* <button onClick={handleLinkedInLogin}>
        Login via LinkedIn
      </button> */}

      <Button
       onClick={handleLinkedInLogin}
      variant="contained"
      startIcon={<LinkedInIcon />}
      style={{
        backgroundColor: '#0077b5',
        color: '#fff',
        padding: '10px 20px',
        textTransform: 'none',
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'center',
      }}
      fullWidth
    >
      Login with LinkedIn
    </Button>
    </div>
  );
};

export default LinkedInLogin;
